// variables 
$black-green: #172723;
$light-cream: #F7EDE2;
$pastel-green: #84A59D;
$pastel-red: #F28482;
$pastel-yellow: #F6BD60;
$subtext-grey: #898E8C;

$light: 300;
$normal: 400;
$bold: 700;

$arvo: 'Arvo', serif;
$lato: 'Lato', sans-serif;

#root  {
  min-height: 100vh;
  height: 100%;
  background-color: $light-cream;

  .pastel-red {
    color: $pastel-red
  }

  .pastel-green {
    color: $pastel-green
  }

  .pastel-yellow {
    color: $pastel-yellow
  }

  h1 {
    font-family: $arvo;
    font-weight: $bold;
    font-size: 40px;
    color: $black-green;
  }

  h3 {
    font-family: $arvo;
    font-weight: $normal;
  }

  h4 {
    font-family: $arvo;
    font-weight: $bold;
    font-style: italic;
  }

  p {
    font-family: $lato;
    font-weight: $light;
  }
}

.main-navbar {
  margin-top: 20px;
  height: 100px; 

  a {
    font-family: $arvo;
    font-weight: $bold;
    color: $pastel-green;
    margin-right: 50px;
  }

  a.active-path {
    border-bottom: 5px solid $pastel-green;
  }

  .social-media-link {
    float: right;
    color: $pastel-green;
  }
}

.main-app-container {
  min-height: 100vh;
  height: 100%;

  .ui.grid {

    .main-banner {
      margin-bottom: 100px;

      .heading-container {
        padding-top: 50px;
        height: 300px;
  
        h1 {
          margin: 0;
        }
  
        h3 {
          color: $subtext-grey;
        }
      }
    }
  }

  .project-snippet {
    margin-bottom: 20px;
  }

  .vertical-divider {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-right: 3px solid;
    height: 100%;
  }
  
}

.app-footer {
  background-color: $pastel-green;

  p.bold {
    font-weight: $bold;
  }
}